import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    /** @name 当前主页导航位置 */
    HMOEPAGENAV_CURRENT: 0,

    /**
     * @name 当前终端
     * @type {boolean}
     */
    TERMINAL: null,

    // /** @name 导航栏 */
    // NavigationBar:[]
  },
  getters: {
    getTerminal: (state) => state.TERMINAL,
  },
  mutations: {
    setHomePagenavCurrent(state, val) {
      state.HMOEPAGENAV_CURRENT = val;
    },
    setTerminal(state, _bool) {
      state.TERMINAL = _bool;
    },
    // setNavigationBar(state, _bool) {
    //   state.NavigationBar = _bool;
    // },
  },
  actions: {},
  modules: {},
});
