<template>
  <div id="app">
    <div v-if="!isMobile && top" class="location" @click="toTop">TOP</div>
    <router-view />
  </div>
</template>
<script>
// import { getNavigationBar } from "@/api/index";
export default {
  components: {},
  data() {
    return {
      isMobile: "",
      top: false,
    };
  },
  created() {
    const _bool = this.$terminal.default.terminalMobilePc();
    this.$store.commit("setTerminal", _bool);
    this.isMobile = _bool;

    // this.$store.commit("setHomePagenavCurrent", 0);
    // this.$router.push("/");

    // getNavigationBar().then((response) => {
    //   // 导航栏
    //   this.$store.commit("setNavigationBar", response);
    // });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      this.$nextTick(() => {
        var top =
          document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageYOffset;
        if (top >= 600) {
          this.top = true;
        } else {
          this.top = false;
        }
      });
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss">
@import url(@/styles/reset.css);
a {
  color: #000 !important;
}
.atvice {
  color: #2795fc !important;
}
.location {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  position: fixed;
  bottom: 150px;
  right: 20px;
  box-shadow: 2px 2px 2px #d1d1d1;
}
</style>
