<template>
  <div id="layout">
    <layout />
  </div>
</template>

<script>
import layout from "./components/layout.vue";
export default {
  components: { layout },
};
</script>
