// 判断访问终端
const browser = {
  versions: (function () {
    var u = navigator.userAgent;
    return {
      trident: u.indexOf("Trident") > -1, // IE内核
      presto: u.indexOf("Presto") > -1, // opera内核
      webKit: u.indexOf("AppleWebKit") > -1, // 苹果、谷歌内核
      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, // 火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, // android终端或者uc浏览器
      iPhone: u.indexOf("iPhone") > -1, // 是否为iPhone或者QQHD浏览器
      iPad: u.indexOf("iPad") > -1, // 是否iPad
      webApp: u.indexOf("Safari") == -1, // 是否web应该程序，没有头部与底部
      weixin: u.indexOf("MicroMessenger") > -1, // 是否微信 （2015-01-22新增）
      qq: u.match(/\sQQ/i) == " qq", // 是否QQ
    };
  })(),
  language: (navigator.browserLanguage || navigator.language).toLowerCase(),
};

const terminalTypeMobilePc = function () {
  if (browser.versions.mobile) {
    // 判断是否是移动设备打开。
    var ua = navigator.userAgent.toLowerCase(); // 获取判断用的对象
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      // 在微信中打开
    }
    if (ua.match(/WeiBo/i) == "weibo") {
      // 在新浪微博客户端打开
    }
    if (ua.match(/QQ/i) == "qq") {
      // 在QQ空间打开
    }
    if (browser.versions.ios) {
      // 是否在IOS浏览器打开
    }
    if (browser.versions.android) {
      // 是否在安卓浏览器打开
    }
    return true;
  } else {
    // PC 浏览器打开
    return false;
  }
};

const terminalMobilePc = function () {
  if (browser.versions.mobile) {
    return true;
  } else {
    // PC 浏览器打开
    return false;
  }
};

const terminalUserAgent = function () {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    // 苹果端
  } else if (/(Android)/i.test(navigator.userAgent)) {
    // 安卓端
  } else {
    // pc端
  }
};

function weChatBrowser() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    // 微信浏览器
  } else {
    // 不是
  }
}

export default {
  terminalTypeMobilePc,
  terminalMobilePc,
  terminalUserAgent,
  weChatBrowser,
};
