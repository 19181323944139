<template>
  <div>
    <div class="topBg">
      <div>
        <div class="row align-items-center">
          <div
            class="col-6"
            v-if="settingLogoShowBoolean"
            @click="handleLink('/', 0)"
          >
            <img class="topImg" :src="imgLogo.img" alt="" />
          </div>
          <div :class="[settingLogoShowBoolean ? 'col-6' : 'col - 12']">
            <div class="row typeface" v-if="!isMobile">
              <a
                :class="[
                  'col',
                  { atvice: $store.state.HMOEPAGENAV_CURRENT == index },
                ]"
                v-for="(item, index) in list"
                :key="item.path"
                @click="handleLink(item.path, index)"
              >
                {{ item.name }}
              </a>
            </div>
            <div class="row-6" v-else align="right">
              <img
                src="@/assets/img/menu.png"
                class="mobileMenuImg"
                alt=""
                @click="handleMenuPopup"
              />
            </div>
          </div>
        </div>
      </div>

      <van-popup
        v-if="isMobile"
        position="right"
        v-model="menuStatus"
        :style="{
          width: '80vw',
          height: '100vh',
        }"
      >
        <!-- <div>
        <div class="">
          <div class="col-12">
            <div class="inpWidth">
              <input class="inpBox" type="text" />
              <button class="btnSearch">搜索</button>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col">猜你喜欢：</div>
              <div class="col">党建引领</div>
              <div class="col">社区食堂</div>
            </div>
          </div>
        </div>
      </div> -->
        <div :class="['mobileMenu']">
          <a
            :class="[{ atvice: $store.state.HMOEPAGENAV_CURRENT == index }]"
            v-for="(item, index) in list"
            :key="item.path"
            @click="handleLink(item.path, index)"
          >
            {{ item.name }}
          </a>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    settingLogoShowBoolean: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: Object.freeze([
        {
          name: "首页",
          path: "/",
        },
        {
          name: "关于我们",
          path: "/main-page/about-us",
        },
        {
          name: "企业服务",
          path: "/main-page/service",
        },
        {
          name: "新闻中心",
          path: "/main-page/news",
        },
        {
          name: "手机端下载",
          path: "/main-page/down",
        },
      ]),
      isMobile: false,
      menuStatus: false,
      imgLogo: {
        img: require("@/static/logo.png"),
      },
    };
  },
  created() {
    this.isMobile = this.$store.state.TERMINAL;
    // if (this.isMobile) {
    //   this.imgLogo = {
    //     img: require("@/static/logo.png"),
    //   };
    // } else {
    //   if (this.$store.state.HMOEPAGENAV_CURRENT == 0) {
    //     this.imgLogo = {
    //       img: require("@/static/logowhite.png"),
    //     };
    //   }
    // }
  },
  methods: {
    handleLink(path, index) {
      // 匹配当前路由与准备跳转的路由是否相同
      if (this.$route.path === path) return;

      // 设置当前路由的小标值,目前用途暂时没有
      this.$store.commit("setHomePagenavCurrent", index);

      this.$router.push(path);
      if (this.isMobile) {
        this.menuStatus = !this.menuStatus;
      }
    },
    handleMenuPopup() {
      this.menuStatus = !this.menuStatus;
    },
  },
};
</script>
<style lang="scss" scoped>
.topBg {
  width: 100%;
  background: #fff;
  font-size: 18px;
  height: 100%;
  border-bottom: 1px solid rgba(138, 139, 139, 0.2);
  padding: 0 30px;
  box-sizing: border-box;
  .row {
    height: 64px;
    line-height: 64px;
  }

  .topImg {
    cursor: pointer;
    margin: 10px 0;
    // width: 200px;
    height: 42px;
    margin-left: 20px;
  }
}
.mobileMenu {
  font-size: 16px;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  a {
    margin: 10px 0;
  }
}
.inpWidth {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  .inpBox {
    width: 70%;
    height: 38px;
    border-radius: 10px 0px 0px 10px;
  }
  .btnSearch {
    font-size: 12px;
    width: 30%;
    height: 38px;
    line-height: 38px;
    background: #2795fc;
    border: none;
    border-radius: 0px 10px 10px 0px;
    color: #fff;
  }
}
@media only screen and (max-width: 960px) {
  .topBg {
    font-size: 14px;
    padding: 0 15px;

    .topImg {
      margin: 10px 0;
      // width: 150px;
      height: 36px;
    }
  }
  .mobileMenuImg {
    width: 32px;
    height: 32px;
  }
}
.typeface {
  font-size: 26px;
}
</style>
