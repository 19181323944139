var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"topBg"},[_c('div',[_c('div',{staticClass:"row align-items-center"},[(_vm.settingLogoShowBoolean)?_c('div',{staticClass:"col-6",on:{"click":function($event){return _vm.handleLink('/', 0)}}},[_c('img',{staticClass:"topImg",attrs:{"src":_vm.imgLogo.img,"alt":""}})]):_vm._e(),_c('div',{class:[_vm.settingLogoShowBoolean ? 'col-6' : 'col - 12']},[(!_vm.isMobile)?_c('div',{staticClass:"row typeface"},_vm._l((_vm.list),function(item,index){return _c('a',{key:item.path,class:[
                'col',
                { atvice: _vm.$store.state.HMOEPAGENAV_CURRENT == index },
              ],on:{"click":function($event){return _vm.handleLink(item.path, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_c('div',{staticClass:"row-6",attrs:{"align":"right"}},[_c('img',{staticClass:"mobileMenuImg",attrs:{"src":require("@/assets/img/menu.png"),"alt":""},on:{"click":_vm.handleMenuPopup}})])])])]),(_vm.isMobile)?_c('van-popup',{style:({
        width: '80vw',
        height: '100vh',
      }),attrs:{"position":"right"},model:{value:(_vm.menuStatus),callback:function ($$v) {_vm.menuStatus=$$v},expression:"menuStatus"}},[_c('div',{class:['mobileMenu']},_vm._l((_vm.list),function(item,index){return _c('a',{key:item.path,class:[{ atvice: _vm.$store.state.HMOEPAGENAV_CURRENT == index }],on:{"click":function($event){return _vm.handleLink(item.path, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }