<template>
  <div>
    <div class="menu__bar">
      <menu-bar :settingLogoShowBoolean="true" />
    </div>
    <router-view />
    <footer-temp />
  </div>
</template>

<script>
import menuBar from "@/components/menu-bar/menu-bar.vue";
import footerTemp from "@/components/footer-temp/footer-temp.vue";
export default {
  components: { footerTemp, menuBar },
  data() {
    return {
      // isMobile: false,
      // settingLogoShowBoolean: true,
    };
  },
  created() {
    // this.isMobile = this.$store.state.TERMINAL;
    // console.log(this.isMobile, "输出layout", this.settingLogoShowBoolean);
    // if (this.isMobile) {
    //   this.settingLogoShowBoolean = true;
    // }
  },
};
</script>

<style lang="scss" scope>
.menu__bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: #fff;
  z-index: 100;
}
</style>
