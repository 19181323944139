import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/home/home.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () =>
      import(/* webpackChunkName: "agreement" */ "@/views/agreement/agreement.vue"),
  },
  {
    path: "/privacyAgreement",
    name: "privacyAgreement",
    component: () =>
      import(/* webpackChunkName: "agreement" */ "@/views/agreement/privacyAgreement.vue"),
  },
  {
    path: "/main-page",
    name: "main-page",
    component: Layout,
    redirect: "/main-page/about-us",
    children: [
      {
        path: "/main-page/about-us",
        name: "about-us",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/about-us/about-us.vue"
          ),
      },
      {
        path: "/main-page/service",
        name: "service",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/service/service.vue"),
      },
      {
        path: "/main-page/news",
        name: "news",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/news/news.vue"),
      },
      {
        path: "/main-page/down",
        name: "down",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/down/down.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { y: 0 };
  },
});

export default router;
