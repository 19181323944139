<template>
  <div class="footer">
    <b-container>
      <b-row class="">
        <b-col cols="12" sm="12" md="12" lg="4" xl="4">
          <div class="row_location">
            <div class="row" align="center">
              <div class="col-6 footerImg">
                <div>
                  <img class="img" src="@/assets/img/gzh.jpg" alt="" />
                  <span>洁佳居家公众号</span>
                </div>
              </div>
              <div class="col-6 footerImg">
                <div>
                  <img class="img" src="@/assets/img/wxXcx.jpg" alt="" />
                  <span>洁佳社区小程序</span>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="4" xl="4">
          <div class="row_location">
            <div class="row">
              <div class="col-1">
                <div class="edge"></div>
              </div>
              <div class="col-10">
                <div class="row" align="center">
                  <div class="col" @click="handleLink(list[0].path, 0)">
                    <a>{{ list[0].name }}</a>
                  </div>
                  <div class="col" @click="handleLink(list[2].path, 2)">
                    <a>{{ list[2].name }}</a>
                  </div>
                </div>
                <div class="row spacer" align="center">
                  <div class="col" @click="handleLink(list[1].path, 1)">
                    <a>{{ list[1].name }}</a>
                  </div>
                  <div class="col" @click="handleLink(list[3].path, 3)">
                    <a>{{ list[3].name }}</a>
                  </div>
                </div>
                <div class="row spacer" align="center">
                  <div class="col">
                    <router-link
                      tag="a"
                      target="_blank"
                      :to="{ name: list[4].path }"
                      >{{ list[4].name }}</router-link
                    >
                  </div>
                  <div class="col">
                    <router-link
                      tag="a"
                      target="_blank"
                      :to="{ name: list[5].path }"
                      >{{ list[5].name }}</router-link
                    >
                  </div>
                  <!-- <a href="" target="_blank">66666</a> -->
                </div>
              </div>
              <div class="col-1">
                <div class="edge"></div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="4" xl="4">
          <div class="md_spacer">
            <div>联系电话：17620371557</div>
            <div>邮箱：jiejia_tech@aliyun.com</div>
            <div>
              地址：深圳市福田区园岭街道华林社区上步北路1018号鹏益花园3栋、4栋313
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="official">
        <a href="https://beian.miit.gov.cn/#/home"
          >Copyright 2021-2022 深圳洁佳网络科技(集团)有限公司 All Rights
          Reserved. 备案号：粤ICP备2022105239号-2</a
        >
      </div>
      <div
        style="
          margin: 0 auto;
          padding: 20px 0;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402005850"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          ><img src="@/assets/img/gn.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #fff;
            "
          >
            粤公网安备 44030402005850号
          </p></a
        >
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      list: Object.freeze([
        {
          name: "首页",
          path: "/",
        },
        {
          name: "关于我们",
          path: "/main-page/about-us",
        },
        {
          name: "企业服务",
          path: "/main-page/service",
        },
        {
          name: "新闻中心",
          path: "/main-page/news",
        },
        {
          name: "用户协议",
          path: "agreement",
        },
        {
          name: "隐私协议",
          path: "privacyAgreement",
        },
      ]),
    };
  },
  methods: {
    handleLink(path, index) {
      // 匹配当前路由与准备跳转的路由是否相同
      if (this.$route.path === path) return;

      // 设置当前路由的小标值,目前用途暂时没有
      this.$store.commit("setHomePagenavCurrent", index);

      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  background: #2795fc;
  padding: 77px 0;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  a {
    color: #ffffff !important;
  }
}
.footerImg {
  div {
    width: 100px;
    height: 150px;
    img,
    span {
      display: block;
      color: #fff;
      text-align: center;
      font-size: 14px;
      margin-top: 10px;
    }
    img {
      width: 100px;
      height: 100px;
    }
  }
}
.spacer {
  margin-top: 15px;
}
.official {
  width: 100%;
  text-align: center;
  margin: 30px auto;
  a {
    margin-top: 100px;
  }
}

.row_location {
  // position: relative;
  .edge {
    position: absolute;
    width: 1px;
    height: 196px;
    background: #fff;
    margin: 0 auto;
    opacity: 0.3;
    // top: -20%;
    // right: 1%;
  }
  .edge_01 {
    // position: absolute;
    width: 1px;
    height: 196px;
    background: #fff;
    margin: 0 auto;
    opacity: 0.3;
    // top: -20%;
    // right: -5%;
  }
}

@media only screen and (max-width: 960px) {
  .footer {
    font-size: 12px;
    font-weight: 200;
    text-align: center;
    padding: 20px 0;
  }
  .row_location {
    .edge {
      width: 0px;
      height: 0px;
    }
    .edge_01 {
      display: none;
    }
  }
  .footerImg {
    div {
      width: 100px;
      height: 130px;
      span {
        font-size: 10px;
      }
      img {
        width: 72px;
        height: 72px;
      }
    }
  }
  .md_spacer {
    margin-top: 30px;
  }
  .official {
    margin: 10px auto;
    a {
      margin: 0;
    }
  }
}
</style>
